<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text">
        Modificar aportes
      </v-card-title>
      <v-data-table
        v-model="aportesSelected"
        :headers="headers"
        :items="ItemsAportesAModificar"
        :loading="loadingTable"
        item-key="aporteId"
        class="elevation-1"
        show-select
        @toggle-select-all="toggleSelectedItems()"
      >
        <template
          v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-simple-checkbox
                :value="isSelected"
                :readonly="item.convenioActualNombre == null"
                :disabled="item.convenioActualNombre == null"
                v-bind="attrs"
                v-on="on"
                color="primary"
                @input="select($event)"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
            <span v-if="item.convenioActualNombre == null"
              >El convenio no puede ser editado por tener alta posterior a la
              fecha del aporte</span
            >
            <span v-else>Seleccionar aporte</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.afiliadoNombre`]="{ item }">
          <span :style="item.convenioActualNombre == null ? 'color:red' : ''">
            {{ item.afiliadoNombre }}</span
          >
        </template>
        <template v-slot:[`item.documento.value2`]="{ item }">
          <span :style="item.convenioActualNombre == null ? 'color:red' : ''">
            {{ item.documento.value2 }}</span
          >
        </template>
        <template v-slot:[`item.cuil`]="{ item }">
          <span :style="item.convenioActualNombre == null ? 'color:red' : ''">
            {{ item.cuil }}</span
          >
        </template>
        <template v-slot:[`item.periodo`]="{ item }">
          <span :style="item.convenioActualNombre == null ? 'color:red' : ''">
            {{ item.periodo }}</span
          >
        </template>
        <template v-slot:[`item.convenioNombre`]="{ item }">
          <span :style="item.convenioActualNombre == null ? 'color:red' : ''">
            {{ item.convenioNombre }}</span
          >
        </template>
      </v-data-table>
      <v-card-actions class="px-0">
        <v-btn color="primary" outlined @click="exportarAportes">
          Exportar detalle
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModalModificarAportes"> Cerrar </v-btn>
        <v-btn
          color="primary"
          :disabled="this.aportesSelected.length == 0"
          @click="modificarAportes()"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-container>
    <DeleteDialog
      :titleProp="titleModificar"
      :maxWidth="'50%'"
      :confirmButtonText="'Modificar'"
      :isLoading="loadingModificar"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmEdit()"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import DeleteDialog from "@/components/shared/DeleteDialog";
export default {
  name: "ModificarConvenioAportes",
  props: {
    ItemsAportes: {
      type: Array,
      required: true,
    },
  },
  components: {
    DeleteDialog,
  },
  data: () => ({
    aportesSelected: [],
    showDeleteModal: false,
    loadingTable: false,
    loadingModificar: false,
    ItemsAportesAModificar: null,
    titleModificar: "¿Desea modificar los aportes seleccionados?",
    disabledCount: 0,
    headers: [
      {
        text: "Afiliado",
        value: "afiliadoNombre",
        sortable: false,
      },
      {
        text: "N° de documento",
        value: "documento.value2",
        sortable: false,
      },
      {
        text: "CUIL",
        value: "cuil",
        sortable: false,
      },
      {
        text: "Período",
        value: "periodo",
        sortable: false,
      },
      {
        text: "Convenio del aporte",
        value: "convenioNombre",
        sortable: false,
      },
      {
        text: "Convenio del afiliado",
        value: "convenioActualNombre",
        sortable: false,
      },
    ],
  }),
  created() {
    this.setAportesAModificar();
  },
  methods: {
    ...mapActions({
      postAportesModificados: "aportes/postAportesModificados",
      setAlert: "user/setAlert",
    }),
    setAportesAModificar() {
      this.disabledCount = 0;
      this.loadingTable = true;
      this.ItemsAportesAModificar = this.ItemsAportes;
      this.ItemsAportesAModificar.map((item) => {
        if (item.convenioActualNombre == null) this.disabledCount += 1;
      });
      this.loadingTable = false;
    },
    tieneConvenioExportar(x) {
      if (x.convenioActualNombre == null)
        return "El convenio no puede ser editado por tener alta posterior a la fecha del aporte";
    },
    exportarAportes() {
      let result = [];
      this.ItemsAportesAModificar?.forEach((x) =>
        result.push({
          ["Afiliado"]: x.afiliadoNombre,
          ["N° de documento"]: x.documento.value2,
          ["CUIL"]: x.cuil,
          ["Período"]: x.periodo,
          ["Convenio del aporte"]: x.convenioNombre,
          ["Convenio del afiliado"]: x.convenioActualNombre,
          ["Observación"]: this.tieneConvenioExportar(x),
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(
        formato,
        "Listado de aportes con distinto convenio"
      );
    },
    modificarAportes() {
      this.showDeleteModal = true;
    },
    async confirmEdit() {
      this.loadingModificar = true;
      try {
        const data = {
          aportesToUpdate: this.aportesSelected,
        };
        const res = await this.postAportesModificados(data);
        if (res.status === 200) {
          this.loadingModificar = false;
          this.setAlert({ type: "success", message: "Modificado con éxito." });
          this.closeModalModificarAportes();
          this.showDeleteModal = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
      }
    },
    closeModalModificarAportes() {
      this.$emit("toggleModalModificarAportes");
    },
    toggleSelectedItems() {
      if (
        this.aportesSelected.length !=
        this.ItemsAportesAModificar.length - this.disabledCount
      ) {
        this.aportesSelected = [];
        this.ItemsAportesAModificar.forEach((item) => {
          if (item.convenioActualNombre !== null)
            this.aportesSelected.push(item);
        });
      } else this.aportesSelected = [];
    },
  },
};
</script>
