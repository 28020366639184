var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.title))])]),_c('v-form',{ref:"select-afiliado",attrs:{"id":"select-afiliado"},on:{"submit":function($event){$event.preventDefault();return _vm.selectAfiliado()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.busquedaAfiliado),expression:"busquedaAfiliado"}]}),_c('v-row',{staticClass:"p-3 pb-0",on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterAfiliados($event)}}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#.###.###', '##.###.###']),expression:"['#.###.###', '##.###.###']"}],attrs:{"label":"N° de documento","dense":"","clearable":"","rules":[
            _vm.rules.requiredTrim(_vm.afiliadoSearchDocumento),
            _vm.rules.minLength(_vm.afiliadoSearchDocumento, 7)
          ],"outlined":""},model:{value:(_vm.afiliadoSearchDocumento),callback:function ($$v) {_vm.afiliadoSearchDocumento=$$v},expression:"afiliadoSearchDocumento"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"N° de afiliado","dense":"","clearable":"","rules":[
            _vm.rules.requiredTrim(_vm.afiliadoSearchNumero),
            _vm.rules.maxLength(_vm.afiliadoSearchNumero, 15)
          ],"outlined":""},model:{value:(_vm.afiliadoSearchNumero),callback:function ($$v) {_vm.afiliadoSearchNumero=$$v},expression:"afiliadoSearchNumero"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-text-field',{staticClass:"theme--light.v-input input",attrs:{"type":"text","label":"Apellido/s","onkeypress":"return /[a-zA-Z\\s]/.test(event.key)","dense":"","rules":[
            _vm.rules.requiredTrim(_vm.afiliadoSearchApellido),
            _vm.rules.maxLength(_vm.afiliadoSearchApellido, 50)
          ],"clearable":"","outlined":""},model:{value:(_vm.afiliadoSearchApellido),callback:function ($$v) {_vm.afiliadoSearchApellido=$$v},expression:"afiliadoSearchApellido"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre/s","dense":"","rules":[
            _vm.rules.requiredTrim(_vm.afiliadoSearchNombre),
            _vm.rules.maxLength(_vm.afiliadoSearchNombre, 50)
          ],"clearable":"","outlined":""},model:{value:(_vm.afiliadoSearchNombre),callback:function ($$v) {_vm.afiliadoSearchNombre=$$v},expression:"afiliadoSearchNombre"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","disabled":!_vm.isFormValid},on:{"click":_vm.filterAfiliados}},[_vm._v(" Buscar ")])],1)],1),_c('v-row',[_c('v-col',[(_vm.filterApplied)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.afiliadosResult,"loading":_vm.loading,"dense":"","show-select":"","single-select":""},on:{"input":function($event){return _vm.selectedAfiliado()}},model:{value:(_vm.afiliadoSelected),callback:function ($$v) {_vm.afiliadoSelected=$$v},expression:"afiliadoSelected"}}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cerrar ")]),_c('v-btn',{ref:"select-afiliado",attrs:{"color":"primary","type":"submit","disabled":_vm.afiliadoSelected.length == 0}},[_vm._v(" Seleccionar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }