var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(" Modificar aportes ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ItemsAportesAModificar,"loading":_vm.loadingTable,"item-key":"aporteId","show-select":""},on:{"toggle-select-all":function($event){return _vm.toggleSelectedItems()}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"value":isSelected,"readonly":item.convenioActualNombre == null,"disabled":item.convenioActualNombre == null,"color":"primary","ripple":false},on:{"input":function($event){return select($event)}}},'v-simple-checkbox',attrs,false),on))]}}],null,true)},[(item.convenioActualNombre == null)?_c('span',[_vm._v("El convenio no puede ser editado por tener alta posterior a la fecha del aporte")]):_c('span',[_vm._v("Seleccionar aporte")])])]}},{key:"item.afiliadoNombre",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.convenioActualNombre == null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.afiliadoNombre))])]}},{key:"item.documento.value2",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.convenioActualNombre == null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.documento.value2))])]}},{key:"item.cuil",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.convenioActualNombre == null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.cuil))])]}},{key:"item.periodo",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.convenioActualNombre == null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.periodo))])]}},{key:"item.convenioNombre",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.convenioActualNombre == null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.convenioNombre))])]}}],null,true),model:{value:(_vm.aportesSelected),callback:function ($$v) {_vm.aportesSelected=$$v},expression:"aportesSelected"}}),_c('v-card-actions',{staticClass:"px-0"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.exportarAportes}},[_vm._v(" Exportar detalle ")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModalModificarAportes}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"primary","disabled":this.aportesSelected.length == 0},on:{"click":function($event){return _vm.modificarAportes()}}},[_vm._v(" Modificar ")])],1)],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleModificar,"maxWidth":'50%',"confirmButtonText":'Modificar',"isLoading":_vm.loadingModificar,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmEdit()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }