<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text">
        {{ isEditing ? titleEdit : titleHistoric }}
        {{ `N° ${actaId} | ${empNombre}` }}
      </v-card-title>
      <v-card-text>
        <v-timeline dense clipped>
          <div v-if="isEditing">
            <v-timeline-item fill-dot class="mb-4" color="primary">
              <v-card>
                <v-container>
                  <v-form v-model="isFormValid">
                    <v-row justify="space-between">
                      <v-col cols="4" sm="6" md="4" class="pb-0">
                        <v-select
                          v-model="estadosActaSelected"
                          ref="estado-acta"
                          :items="estados"
                          item-text="nombre"
                          item-value="id"
                          label="Estado"
                          outlined
                          dense
                          :rules="rules.required"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="4" sm="6" md="4" class="pb-0">
                        <v-menu
                          v-model="menuFecha"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fechaSelected"
                              label="Fecha"
                              :append-icon="calendarIcon"
                              v-bind="attrs"
                              hint="Formato DD/MM/AAAA"
                              @focus="(menuFecha = true), (menuHora = false)"
                              @blur="fecha = parseDateToIso(fechaSelected)"
                              outlined
                              dense
                              v-on="on"
                              :rules="rules.required"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fecha"
                            no-title
                            @change="fechaSelected = formatDate(fecha)"
                            @input="menuFecha = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="3" sm="6" md="4" class="pb-0">
                        <v-text-field
                          outlined
                          dense
                          v-model="time"
                          v-mask="'##:##'"
                          label="Hora"
                          :append-icon="timeIcon"
                          :rules="rules.required"
                          format="24hr"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pb-0 pt-0">
                        <v-textarea
                          class="observaciones"
                          v-model="observaciones"
                          hide-details
                          flat
                          label="Observaciones..."
                          dense
                          outlined
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-row justify="end">
                        <v-btn
                          :disabled="!isFormValid"
                          class="mr-2"
                          color="primary"
                          small
                          @click="saveEstadoActa"
                        >
                          {{ add }}
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </v-container>
              </v-card>
            </v-timeline-item>

            <!-- Nuevo Estado que se agrega a la base -->
            <v-slide-x-transition group>
              <v-timeline-item
                v-for="estado in timeline"
                :key="estado.id"
                class="mb-4"
                color="success"
                small
              >
                <v-card>
                  <v-container>
                    <v-row justify="space-between">
                      <v-col cols="4" align-self="center">
                        {{ joinDateAndTime(fechaSelected, time) }}
                        <h6>{{ estado.nombre }}</h6>
                      </v-col>
                      <v-col
                        cols="4"
                        offset="4"
                        align-self="center"
                        align="end"
                      >
                        <p class="my-label">Fecha alta</p>
                        {{ joinDateAndTime(estado.fecAlta, time) }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="9" class="pb-0 observaciones">
                        {{ estado.observaciones }}
                      </v-col>
                      <v-col
                        align="end"
                        align-self="center"
                        cols="3"
                        class="pb-0"
                      >
                        <p v-if="estado.usuAlta" class="my-label">
                          Usuario alta
                        </p>
                        {{ estado.usuAlta }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
            </v-slide-x-transition>
          </div>

          <!-- Datos traidos de base -->
          <v-timeline-item
            v-for="estadoActa in estadosActa"
            :key="estadoActa.actaFiscalizacionEstadosId"
            class="mb-4"
            color="grey"
            small
          >
            <v-card>
              <v-container>
                <v-row justify="end" class="pr-3">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        v-show="estadoActa.showDelete && isEditing"
                        @click="openDeleteEstado(estadoActa)"
                      >
                        {{ closeIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar estado</span>
                  </v-tooltip>
                </v-row>
                <v-row justify="space-between">
                  <v-col cols="4" align-self="center">
                    {{ parseFechaEstado(estadoActa.fecha) }}
                    <h6>{{ estadoActa.nombre }}</h6>
                  </v-col>
                  <v-col cols="4" offset="4" align-self="center" align="end">
                    <p class="my-label">Fecha alta</p>
                    {{ estadoActa.fecAlta.slice(0, 10) }}
                    {{ estadoActa.fecAlta.slice(11, 16) }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9" class="pb-0 observaciones">
                    {{ estadoActa.observaciones }}
                  </v-col>
                  <v-col align="end" align-self="center" cols="3" class="pb-0">
                    <p v-if="estadoActa.usuAlta" class="my-label">
                      Usuario alta
                    </p>
                    {{ estadoActa.usuAlta }}
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-4">
          <v-btn outlined dense @click="$emit('close')">Cerrar</v-btn>
        </v-row>
      </v-card-actions>
      <DeleteDialog
        :textDelete="textDelete"
        :openDelete.sync="openDeleteDialog"
        @onDeleteItem="deleteEstado()"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mask } from "vue-the-mask";


export default {
  name: "HistoricEstadoActa",
  directives: { mask },
  props: {
    actaId: {
      type: Number,
      required: true
    },
    empNombre: {
      type: String,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  components: {
    DeleteDialog
  },

  data: () => ({
    isFormValid: false,
    add: enums.buttons.CONFIRMAR,
    estadosActaSelected: null,
    formEditTitle: "Historial",
    titleEdit: enums.titles.EDITAR_ESTADO_ACTA,
    titleHistoric: enums.titles.HISTORIAL_ESTADO_ACTA,
    calendarIcon: enums.icons.CALENDAR,
    timeIcon: enums.icons.TIME,
    fecha: new Date().toISOString().substr(0, 10),
    fecAlta: new Date().toISOString().substr(0, 10),
    menuFecha: false,
    menuHora: false,
    menuVencimiento: false,
    textDelete: null,
    formatoToDelete: {},
    closeIcon: enums.icons.CLOSE,
    openDeleteDialog: false,
    showDelete: false,
    fechaSelected: null,
    observaciones: null,
    ultimoEstadoId: 0,
    nonce: 0,
    time: null,
    nombre: null,
    estadoActaId: null,
    newEstadoActa: [],
    estadosActa: [],
    estados: [],
    rules: rules
  }),
  created() {
    this.loadHistorialEstados();
    //Formato de la hora para el timePicker
    this.getTime();
  },
  computed: {
    ...mapGetters({ user: "user/user" }),
    timeline() {
      return this.newEstadoActa.slice().reverse();
    }
  },
  methods: {
    ...mapActions({
      fetchActaFiscalizacionEstados: "aportes/fetchActaFiscalizacionEstados",
      fetchEstadosActaDestino: "aportes/fetchEstadosActaDestino",
      updateActaFiscalizacionEstado: "aportes/updateActaFiscalizacionEstado",
      deleteEstadoActaFiscalizacion: "aportes/deleteEstadoActaFiscalizacion",
      getDateTime: "aportes/getDateTime",
      setAlert: "user/setAlert"
    }),
    async getTime() {
      const response = await this.getDateTime();
      this.time = response.slice(10, 16);
    },
    async loadEstados() {
      const res = await this.fetchEstadosActaDestino(this.ultimoEstadoId);
      this.estados = res;
    },
    async loadHistorialEstados() {
      const response = await this.fetchActaFiscalizacionEstados({
        actaId: this.actaId
      });
      this.estadosActa = response;
      if (this.estadosActa.length > 1) {
        this.estadosActa[0].showDelete = true;
      }
      if (this.isEditing) {
        this.fechaSelected = this.formatDate(this.fecha);
        this.ultimoEstadoId = this.estadosActa[0].estadoActaId;
      }
      this.loadEstados();
    },
    async saveEstadoActa() {
      this.isFormValid = false;
      this.estadoActaId = this.estados.find(
        x => x.nombre === this.estadosActaSelected
      ).estadoActaDestinoId;
      const fechaNow = new Date();
      const fechaSelected = new Date(this.parseDate(this.fecha, this.time));
      const fechaAlta = new Date(
        this.convertFecha(this.estadosActa[0].fecha)
      );
      if (fechaSelected < fechaAlta || fechaSelected > fechaNow) {
        this.setAlert({
          type: "error",
          message:
            "La fecha no puede ser menor que la fecha del último estado y mayor a la fecha de hoy."
        });
      } else {
        const data = {
          actasFiscalizacionId: this.actaId,
          estadoActaId: this.estadoActaId,
          fecha: this.joinDateAndTimeToBE(this.fecha, this.time),
          observaciones: this.observaciones,
          usuAlta: 0,
          fecAlta: this.joinDateAndTimeToBE(this.fecAlta, this.time)
        };
        const save = await this.updateActaFiscalizacionEstado(data);
        if (save.status === 200) {
          this.addEstadoActa();
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          setTimeout(() => {
            this.$emit("closeAndReload");
          }, 2000);
        }
      }
    },
    addEstadoActa() {
      this.isFormValid = false;
      this.newEstadoActa.push({
        id: this.nonce++,
        estadoActaId: this.estadoActaId,
        nombre: this.estadosActaSelected,
        fecha: this.formatDate(this.fechaSelected),
        observaciones: this.observaciones,
        time: this.time,
        usuAlta: this.user.nombre,
        fecAlta: this.formatDate(this.fecAlta)
      });
    },
    openDeleteEstado(item) {
      this.formatoToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteEstado() {
      const res = await this.deleteEstadoActaFiscalizacion(
        this.formatoToDelete.actasFiscalizacionEstadosId
      );
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.$emit("closeAndReload");
      }
      this.formatoToDelete = {};
      this.textDelete = null;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(fecha, hora) {
      return `${fecha}T${hora}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    parseFechaEstado(fecha) {
      return `${fecha.slice(0, 10)} - ${fecha.slice(11, 16)} hs.`;
    },
    joinDateAndTime(fecha, time) {
      return `${fecha} - ${time}hs.`;
    },
    joinDateAndTimeToBE(fecha, time) {
      const fechaFormatted = this.formatDate(fecha);
      return `${fechaFormatted} ${time}`;
    },
    convertFecha(fechaCompleta) {
      const miFecha = `${fechaCompleta.slice(0, 10)}`;
      const miHora = `${fechaCompleta.slice(11, 16)}`;
      const miFechaISO = this.parseDateToIso(miFecha);
      return `${miFechaISO}T${miHora}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.my-label {
  margin: 0;
  font-size: 12px;
  opacity: 0.8;
}
.observaciones {
  white-space: pre;
}
</style>
